import { URL_ONBOARDING_TASKS } from "constants/urls";
import { useSWRGlobalMutation } from "contexts/SWRContext";
import useSWR from "swr";
import { CompleteTask } from "./types";
import fireTheConfetti from "utils/fire-the-confetti";
import { completeTaskCount } from "./OnboardingTaskList";
import { ONBOARDING_CATEGORY_LIST, TOTAL_ONBOARDING_TASKS } from "./tasks";
import { useMemo } from "react";

type UseOnboardingTasksReturn = {
    /** All tasks completed by the current user */
    completedTasks: CompleteTask[] | undefined;
    /** A count of all completed tasks that match the current hard-coded onboarding tasks */
    completedTaskCount: number;
    /** Whether or not all hard-coded onboarding tasks have been completed */
    allTasksComplete: boolean;
    /** Whether or not the onboarding tasks are currently loading */
    isLoadingTasks: boolean;
    /** A callback to mark a task as done */
    markAsDone: (taskId: string, metadata?: any) => Promise<void>;
};

export const useOnboardingTasks = (): UseOnboardingTasksReturn => {
    const {
        data: completedTasks,
        isLoading: isLoadingTasks,
        mutate: mutateTasks
    } = useSWR<CompleteTask[]>(URL_ONBOARDING_TASKS);

    const completedTaskCount = useMemo(
        () =>
            completedTasks
                ? ONBOARDING_CATEGORY_LIST.reduce(
                      (total, { tasks }) => total + completeTaskCount(tasks, completedTasks),
                      0
                  )
                : 0,
        [completedTasks?.length]
    );

    const { trigger: postTaskComplete } = useSWRGlobalMutation<
        CompleteTask,
        Error,
        { data: { task: string; metadata: any } }
    >(
        { url: URL_ONBOARDING_TASKS, method: "POST" },
        {
            onSuccess: task => {
                if (!completedTasks) return;

                const taskIndex = completedTasks.findIndex(
                    completeTask => completeTask.task === task.task
                );
                const newTaskList =
                    taskIndex >= 0
                        ? completedTasks.with(taskIndex, task)
                        : [...completedTasks, task];
                mutateTasks(newTaskList);
            },
            onError: (error: Error) => {
                console.error("Error marking task complete", error);
            },
            revalidate: true
        }
    );

    return {
        completedTasks,
        completedTaskCount,
        allTasksComplete: completedTaskCount >= TOTAL_ONBOARDING_TASKS,
        isLoadingTasks,
        markAsDone: async (taskId: string, metadata?: any) => {
            await postTaskComplete({ data: { task: taskId, metadata } }).then(res => {
                fireTheConfetti();
                return res;
            });
        }
    };
};
