import { DateTime, Info } from "luxon";
import {
    DATE_FORMAT_LLL_D,
    DATE_FORMAT_SLASHED_M_D_YY,
    DATE_FORMAT_YYYY_LL_DD,
    defaultLocale
} from "constants/index";
import pluralize from "pluralize";
import {
    differenceInSeconds,
    differenceInMinutes,
    differenceInHours,
    differenceInDays,
    differenceInWeeks,
    differenceInMonths
} from "date-fns";

export const transformJsDateToFormat = (
    date?: Date,
    format: string = DATE_FORMAT_SLASHED_M_D_YY
): string => (date ? DateTime.fromJSDate(date).toFormat(format) : "");

export const calculateAge = (birthday?: {
    year: number | null;
    month: number | null;
    day: number | null;
}): { label: string; value?: number; unit?: "years" | "months" | "days" } => {
    if (!birthday || !birthday.year || !birthday.month || !birthday.day) {
        return { label: "" };
    }
    var end = DateTime.now().startOf("day");
    var start = DateTime.local(birthday.year, birthday.month, birthday.day);
    const diffInYears = Math.floor(end.diff(start, "years").years);
    if (diffInYears > 0) {
        return {
            label: `${diffInYears} ${pluralize("year", diffInYears)}`,
            value: diffInYears,
            unit: "years"
        };
    }
    const diffInMonths = Math.floor(end.diff(start, "months").months);
    if (diffInMonths > 0) {
        return {
            label: `${diffInMonths} ${pluralize("month", diffInMonths)}`,
            value: diffInMonths,
            unit: "months"
        };
    }
    const diffInDays = Math.floor(end.diff(start, "days").days);
    return {
        label: `${diffInDays} ${pluralize("day", diffInDays)}`,
        value: diffInDays,
        unit: "days"
    };
};

export const transformDateFormat = (
    dateValue?: string,
    fromFormat: string = DATE_FORMAT_YYYY_LL_DD,
    toFormat: string = DATE_FORMAT_SLASHED_M_D_YY,
    origin: boolean = true
): string | undefined => {
    let convertedFormat: string | undefined = undefined;

    if (!dateValue) return convertedFormat;
    if (DateTime.fromFormat(dateValue, fromFormat).isValid) {
        convertedFormat = DateTime.fromFormat(dateValue, fromFormat).toFormat(toFormat);
    } else {
        convertedFormat = origin ? dateValue : undefined;
    }

    return convertedFormat;
};

export const shortDateFormat = (dateStr: string): string => {
    return DateTime.fromFormat(dateStr, DATE_FORMAT_YYYY_LL_DD).toFormat(DATE_FORMAT_LLL_D);
};
/** returns short day 2024-01-05 return 1 */
export const getDay = (dateStr: string): string => {
    return DateTime.fromFormat(dateStr, DATE_FORMAT_YYYY_LL_DD).toFormat("d");
};

export const getDifferenceInDays = (date1: string, date2: string): number => {
    // ignore DST and only measure exact 24-hour periods
    return Math.trunc(differenceInHours(date2, date1) / 24) | 0;
};

export const getTimeAgo = (date: string | null): { value: number; unit: string } | null => {
    if (!date) return null;
    const now = new Date();
    const then = new Date(date);

    const seconds = differenceInSeconds(now, then);
    if (seconds < 60) return { value: seconds, unit: "second" };
    const minutes = differenceInMinutes(now, then);
    if (minutes < 60) return { value: minutes, unit: "minute" };
    const hours = differenceInHours(now, then);
    if (hours < 24) return { value: hours, unit: "hour" };
    const days = differenceInDays(now, then);
    if (days < 7) return { value: days, unit: "day" };
    const weeks = differenceInWeeks(now, then);
    if (weeks < 5) return { value: weeks, unit: "week" };
    const months = differenceInMonths(now, then);
    return { value: months, unit: "month" };
};

/** Returns a date's time formatted like 1:23pm */
export const get12HourTime = (date: DateTime): string => date.toFormat("h:mma").toLowerCase();

export const isToday = (date: Date): boolean => {
    return DateTime.fromJSDate(date).hasSame(DateTime.now(), "day");
};

export const formatTimeNoMinutes = ({ h, m }: { h: string; m: string }): string => {
    if (m === "00") return h;
    return `${h}:${m}`;
};

/* JS Date and API request has index of month from 1 to 12 range. Info.months() returns simple array of months started from index == 0*/
export const getShortNameMonth = (id: number) =>
    Info.months("short", { locale: defaultLocale })[id - 1];

/** Returns true if the given date is valid */
export const isValidDate = (date: Date | null | undefined): date is Date =>
    !!date && !isNaN(date.valueOf());

/** Returns the given date if it is valid, or undefined otherwise */
export const validDate = (date: Date | null | undefined): Date | undefined =>
    isValidDate(date) ? date : undefined;
