import { ReactNode } from "react";

/** A component to display within an Onboarding task */
export type TaskWidget = (props: {
    completed: boolean;
    markAsDone: (metadata?: any) => Promise<void>;
    metadata?: Record<string, any>;
}) => JSX.Element;

/** A custom button within an Onboarding task */
export type TaskCustomButton = (props: { taskId: string; completed: boolean }) => JSX.Element;

/** A single task in Onboarding */
export type OnboardingTask = {
    /** An id for the task, associated with BE data */
    id: string;
    /** Task display title */
    title: string;
    /** Task rundown */
    description?: ReactNode;
    /** The time to complete the task */
    time?: string;
    /** A component that can take the place of a standard task description, for interactive tasks */
    Widget?: TaskWidget;
    /** A link to an associated training resource */
    taskLinkUrl?: string;
    /** The text for the link button */
    taskLinkText?: string;
    /** If true, link opens in a new tab, and Mark as done is hidden until clicked */
    linkNewTab?: boolean;
    /** A custom button/link */
    CustomButton?: TaskCustomButton;
    /** If true, this task will have a Mark as done button. */
    showMarkAsDone?: boolean;
};

/** A group of Onboarding tasks */
export type OnboardingCategory = {
    /** An id that can be used in the url for a category page */
    id: string;
    title: string;
    description: string;
    icon: JSX.Element;
    /** The tasks in the category */
    tasks: OnboardingTask[];
};

/** A completed task, as it is returned from the backend */
export type CompleteTask = {
    task: string;
    completed_at: string;
    metadata: any;
};

/** Returns true if the given hard-coded task is complete (in the completedTasks list) */
export const taskComplete = (completedTasks: CompleteTask[], task: OnboardingTask): boolean =>
    completedTasks.some(completeTask => completeTask.task === task.id);
