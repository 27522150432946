import { useEffect, useMemo, useState } from "react";
import { CheckCircle, Circle, Timer } from "phosphor-react";
import { twMerge } from "tailwind-merge";
import Link from "next/link";
import PortalAnalytics from "PortalAnalytics";
import { URI_GET_STARTED } from "constants/urls";
import { Button, getButtonStyles } from "components/common/v2/button/Button";
import { CompleteTask, OnboardingCategory, OnboardingTask, taskComplete } from "./types";
import { Transition, TransitionChild } from "@headlessui/react";
import fireTheConfetti from "utils/fire-the-confetti";
import React from "react";

const COMPLETION_ICON_SIZE = 24;

type TaskLinkProps = {
    taskId: string;
    completed: boolean;
    linkUrl: string;
    linkText: string;
    newTab?: boolean;
};

/** A link out to a Get Started resource */
export const TaskLink = ({
    taskId,
    completed,
    linkUrl,
    linkText,
    newTab
}: TaskLinkProps): JSX.Element => (
    <Link
        className={getButtonStyles({
            variant: !completed ? "primary" : "secondary"
        })}
        href={linkUrl}
        target={newTab ? "_blank" : undefined}
        onClick={() =>
            PortalAnalytics.track("onboarding__follow_task_link", {
                task_id: taskId
            })
        }
    >
        {linkText}
    </Link>
);

type TaskDetailsProps = {
    task: OnboardingTask;
    completed: boolean;
    markAsDone: (metadata?: any) => Promise<void>;
    showMarkAsDone: boolean;
    metadata?: Record<string, any>;
};

/** The contents of an expandable Onboarding task (description, widget, links/buttons) */
const TaskDetails = ({
    task: {
        id: taskId,
        description,
        time,
        Widget,
        taskLinkUrl,
        taskLinkText,
        linkNewTab,
        CustomButton
    },
    completed,
    markAsDone,
    showMarkAsDone,
    metadata
}: TaskDetailsProps): JSX.Element => {
    const [markingAsDone, setMarkingAsDone] = useState(false);

    const linkButton = taskLinkUrl && taskLinkText && (
        <TaskLink
            taskId={taskId}
            completed={completed}
            linkUrl={taskLinkUrl}
            linkText={taskLinkText}
            newTab={linkNewTab}
        />
    );

    const customButton = CustomButton && <CustomButton taskId={taskId} completed={completed} />;

    const markDoneButton = !completed && showMarkAsDone && (
        <Button
            variant="text"
            onClick={async () => {
                PortalAnalytics.track("onboarding__mark_as_done", {
                    task_id: taskId
                });
                setMarkingAsDone(true);
                await markAsDone().finally(() => setMarkingAsDone(false));
            }}
            disabled={markingAsDone}
        >
            Mark as done
        </Button>
    );

    const showButtons = linkButton || customButton || markDoneButton;

    return (
        <div className="flex flex-col justify-between pl-8 md:items-center md:flex-row gap-x-8 gap-y-4 w-full">
            {Widget && <Widget completed={completed} markAsDone={markAsDone} metadata={metadata} />}
            {(description || time) && (
                <div className="flex flex-col gap-y-2 text-secondaryDark">
                    {description}
                    {time && (
                        <div className="flex gap-x-1 items-center">
                            <Timer size={16} />
                            <span>{time}</span>
                        </div>
                    )}
                </div>
            )}
            {showButtons && (
                <div className="flex gap-x-2">
                    {linkButton}
                    {customButton}
                    {markDoneButton}
                </div>
            )}
        </div>
    );
};

export type OnboardingTaskItemProps = {
    /** The task to pull details from */
    task: OnboardingTask;
    /** Whether or not the task is complete */
    completed: boolean;
    /** Whether or not the task is currently showing its description and buttons */
    expanded: boolean;
    /** Callback for when an unexpanded item is clicked */
    expand: () => void;
    /** Callback to mark a task as complete */
    markAsDone: (taskId: string, metadata?: any) => Promise<void>;
    /** Metadata for the given task, if it is completed */
    metadata?: any;
};

/** An onboarding task in a list, which can be expanded to show details on how to complete it */
export const OnboardingTaskListItem = ({
    task,
    completed,
    expanded,
    expand,
    markAsDone,
    metadata
}: OnboardingTaskItemProps): JSX.Element => {
    const completionIcon = !completed ? (
        <Circle size={COMPLETION_ICON_SIZE} />
    ) : (
        <CheckCircle className="fill-success" size={COMPLETION_ICON_SIZE} weight="fill" />
    );

    return (
        <div
            className={twMerge(
                "flex flex-col p-6 gap-y-2 md:rounded-lg", // TODO: Confirm hover color
                !expanded
                    ? "hover:bg-[#1414140f] cursor-pointer"
                    : !completed
                    ? "bg-lightGreyBg"
                    : "bg-successSecondary",
                !expanded && completed && "text-tertiaryOld hover:text-secondaryDark"
            )}
            onClick={!expanded ? expand : undefined}
        >
            <div className="flex gap-x-2">
                {completionIcon}
                <span className="font-medium">{task.title}</span>
            </div>
            <Transition
                show={expanded}
                as="div"
                enter="ease-in-out duration-300"
                enterFrom="max-h-0 overflow-hidden"
                enterTo="max-h-screen overflow-hidden"
                leave="ease-in-out duration-300"
                leaveFrom="max-h-screen overflow-hidden"
                leaveTo="max-h-0 overflow-hidden"
            >
                <TaskDetails
                    task={task}
                    completed={completed}
                    markAsDone={metadata => markAsDone(task.id, metadata)}
                    showMarkAsDone={!!task.showMarkAsDone}
                    metadata={metadata}
                />
            </Transition>
        </div>
    );
};

/** Returns the count of completed tasks, based on the number of task ids present in both the tasks and completedTasks */
export const completeTaskCount = (
    tasks: OnboardingTask[],
    completedTasks: CompleteTask[]
): number => tasks.reduce((t, task) => (taskComplete(completedTasks, task) ? t + 1 : t), 0);

type CategoryTaskListProps = {
    category: OnboardingCategory;
    completedTasks: CompleteTask[];
    markAsDone: (taskId: string, metadata?: any) => Promise<void>;
};

/** A list of an OnboardingCategory's tasks (interactive)  */
export const OnboardingTaskList = ({
    category: { id: categoryId, title, description, tasks },
    completedTasks,
    markAsDone
}: CategoryTaskListProps): JSX.Element => {
    const [openTaskId, setOpenTaskId] = useState<string | undefined>(undefined);
    const startedComplete = useMemo(
        () => completeTaskCount(tasks, completedTasks) >= tasks.length,
        []
    );

    const theyDidItCongrats =
        !startedComplete && completeTaskCount(tasks, completedTasks) >= tasks.length;

    useEffect(() => {
        if (theyDidItCongrats) fireTheConfetti();
    }, [theyDidItCongrats]);

    const content = !theyDidItCongrats ? (
        <div className="flex flex-col gap-y-2">
            {tasks.map(task => (
                <OnboardingTaskListItem
                    key={task.id}
                    task={task}
                    completed={taskComplete(completedTasks, task)}
                    expanded={openTaskId === task.id}
                    expand={() => setOpenTaskId(task.id)}
                    markAsDone={markAsDone}
                    metadata={
                        completedTasks.find(completedTask => completedTask.task === task.id)
                            ?.metadata
                    }
                />
            ))}
        </div>
    ) : (
        <div className="flex flex-col gap-y-6 py-6 items-center">
            <CheckCircle className="fill-success" size={32} weight="fill" />
            <div className="flex flex-col gap-y-2 items-center">
                <span className="text-main font-medium">Congrats!</span>
                <span className="text-secondaryDark">You’ve completed this section.</span>
            </div>
            <Link
                className={getButtonStyles({}, "w-fit")}
                href={URI_GET_STARTED}
                onClick={() =>
                    PortalAnalytics.track("onboarding__close_complete_task_list", {
                        category_id: categoryId
                    })
                }
            >
                Continue
            </Link>
        </div>
    );

    return (
        <>
            <div className="flex flex-col gap-y-2 px-6 md:px-0">
                <span className="text-header">{title}</span>
                <span className="text-secondaryDark">{description}</span>
            </div>
            {content}
        </>
    );
};
