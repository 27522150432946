import PortalAnalytics from "analytics/PortalAnalytics";

export interface IGettingStartedAnalytics {
    email: string;
    first_name: string;
    last_name: string;
}

interface IOnboardingScheduled extends IGettingStartedAnalytics {
    source: "calendly" | "checkbox";
    invitation_id: string;
}

export interface IOnboardingWidgetCompanyInfoCopied extends IGettingStartedAnalytics {
    contentType: string;
}
interface IOnboardingWidgetBookingsCardFilterApplied extends IGettingStartedAnalytics {
    filterApplied: string;
}

interface IOnboardingRecommendedNextStepsClicked {
    linkTitle: string;
    linkDestination: string;
}

export function ScheduledOnboardingWebinar({ invitation_id, source }: IOnboardingScheduled): void {
    PortalAnalytics.track("onboarding__scheduled_onboarding_webinar", {
        invitation_id,
        source
    });
}

export function EventDateTimeSelected({
    email,
    first_name,
    last_name
}: IGettingStartedAnalytics): void {
    PortalAnalytics.track("onboarding__calendly_date_time_selected", {
        email,
        first_name,
        last_name
    });
}

/*
    # Bookings Card Widget Events
*/

export function OnboardingWidgetBookingsCardFilterApplied({
    filterApplied
}: IOnboardingWidgetBookingsCardFilterApplied): void {
    PortalAnalytics.track("onboarding__widget_bookings_card_filter_applied", {
        filterApplied
    });
}

export function OnboardingWidgetBookingViewBookingsClicked({}: IGettingStartedAnalytics): void {
    PortalAnalytics.track("onboarding__widget_booking_view_bookings_clicked");
}

/*
    # Company Info Widget Card Events
*/

export function OnboardingWidgetCompanyInfoCopied({
    contentType
}: IOnboardingWidgetCompanyInfoCopied): void {
    PortalAnalytics.track("onboarding__widget_company_info_copied", {
        contentType
    });
}

/*
    # Recommended Next Steps Events
*/

export function OnboardingRecommendedNextStepsClicked({
    linkTitle,
    linkDestination
}: IOnboardingRecommendedNextStepsClicked): void {
    PortalAnalytics.track("homepage__link_clicked", {
        linkTitle,
        linkDestination
    });
}
