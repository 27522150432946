import { UserProfile } from "api/user/profile";
import { parsePhoneNumber } from "react-phone-number-input";
import { TaskLink } from "./OnboardingTaskList";
import { TaskCustomButton } from "./types";
import { useAuth } from "hooks/use-auth";

const EMAIL_SUBJECT = "Meet your new travel expert (& perks for you!)";

const EMAIL_BODY = `Hi [friends & family/colleagues/fellow travelers],

As you may already know, I am a huge travel enthusiast, so I am so excited to share with you that I’ve taken this passion to the next level and joined Fora as a professional travel advisor.

What does this mean for you? It means I can hook you up at the best hotels around the world with upgrades, perks, resort credit, free breakfast and more by booking your stay.

The best part is booking through a travel advisor (i.e. me) comes at no additional cost to you! By joining Fora, I have access to a deeply collaborative community of travel experts from around the world that work together to ensure that you have the best trip imaginable.

Respond directly to this email for help planning your next trip – whether that’s a staycation locally or your spring break getaway. Or if you have someone in your community who has upcoming travel (or really needs a vacation), forward this email along.

Thanks for your support in this exciting new endeavor!

Your friend,`;

/** Insert's the user's info into a generic "hey I just joined Fora" email */
export const generateEmailBody = ({ firstName, lastName, email, phone }: UserProfile): string => `
${EMAIL_BODY}
${firstName} ${lastName}
${email || "name.name@fora.travel"}
${parsePhoneNumber(phone)?.format("NATIONAL") ?? "(555) 555-5555"}
`;

/** A custom Draft an email button for one of the reach out tasks */
export const ReachOutEmailLink: TaskCustomButton = ({ taskId, completed }): JSX.Element => {
    const { userProfile } = useAuth();
    return (
        <TaskLink
            taskId={taskId}
            completed={completed}
            linkUrl={`https://mail.google.com/mail?${new URLSearchParams([
                // Compose Message
                ["view", "cm"],
                // Subject
                ["su", EMAIL_SUBJECT],
                // Body
                ["body", generateEmailBody(userProfile)]
            ])}`}
            linkText="Draft an email"
            newTab
        />
    );
};
